<template>
  <nav class="panel">
    <div class="panel-heading">
      <div class="has-text-centered" v-if="!instituteOwn.code"><button @click="showGuruRegister" class="button is-success has-text-weight-medium">Create Assignment Now</button></div>
      <div v-if="instituteOwn.code" class="has-text-weight-bold has-text-grey-darker">Your Institute</div>
    </div>
    <div class="panel-block is-paddingless" v-if="!instituteOwn.code">
      <table class="table is-bordered is-fullwidth">
        <tbody>
        <tr>
          <th>Price</th>
          <td class="has-text-weight-bold has-text-centered has-text-info">Free</td>
        </tr>
        <tr>
          <th>Private Students</th>
          <td>Unlimited</td>
        </tr>
        <tr>
          <th>Public Students (coming soon)</th>
          <td>Unlimited</td>
        </tr>
        <tr>
          <th>Teachers</th>
          <td>Unlimited</td>
        </tr>
        <tr>
          <th>Private Assignments</th>
          <td>Unlimited</td>
        </tr>
        <tr>
          <th>Public Assignments (coming soon)</th>
          <td>Unlimited</td>
        </tr>
        <tr>
          <th>Private Courses (coming soon)</th>
          <td>Unlimited</td>
        </tr>
        <tr>
          <th>Public Courses (coming soon)</th>
          <td>Unlimited</td>
        </tr>
        <tr>
          <th>Embed to blog/website (coming soon)</th>
          <td>Unlimited</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="panel-block has-text-centered" v-if="instituteOwn.code">
      <router-link class="is-size-5" :to="instituteUrl">{{instituteOwn.name}}</router-link>
    </div>
  </nav>
</template>

<script>
import { eventBus } from '../../assets/javascript/event-bus'
import { GURU_EVENTS } from '../../assets/javascript/constants'

export default {
  name: 'GuruSubscription',
  inject: ['$validator'],
  props: ['instituteOwn'],
  data: function () {
    return {

    }
  },
  computed: {
    instituteUrl () {
      return '/guru/ins/' + this.instituteOwn.code
    }
  },
  methods: {
    showGuruRegister () {
      if (this.$store.state.isUserLoggedIn === false) {
        eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('../Login'))
      } else {
        eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('./GuruRegister'), { mode: 'add' })
      }
    }
  }
}
</script>

<style>
  .panel-heading {
    font-family: serif;
    font-size: 16px;
    letter-spacing: 1px;
    color: #333;
  }
  .panel-block {
    display: block;
  }
</style>
