<template>
  <nav class="panel">
    <p class="panel-heading has-text-weight-bold has-text-grey-darker">
      Your Virtual Institute Associations
    </p>
    <div class="panel-block has-text-centered" v-if="!associations || associations.length<1">
        Your are not associated with any institute yet.
    </div>
    <div class="panel-block has-text-centered is-paddingless" v-if="associations && associations.length>0">
      <table class="table is-bordered is-fullwidth">
        <thead>
          <tr>
            <th class="has-text-centered">Institute</th>
            <th class="has-text-centered">Role(s)</th>
            <th class="has-text-centered">Status</th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="association in associations">
          <td class="has-text-centered"><router-link :to="'/guru/ins/' + association.institute.code">{{association.institute.name}}</router-link></td>
          <td class="has-text-centered"><div v-for="associationType in association.associationTypes"> {{associationType}}</div></td>
          <td class="has-text-centered">{{association.status}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'InstituteAssociations',
  props: ['associations'],
  data: function () {
    return {

    }
  },
  methods: {
    search () {

    }
  }
}
</script>

<style>

</style>
