<template>
  <div class="home-container">
    <TeachPage v-if="this.$store.state.isUserLoggedIn === false || associations.length<1"/>
    <div class="main-section" v-if="associations.length>0">
      <div class="has-text-centered guru-title">JDoodle Guru - Beta</div>
      <div class="has-text-centered guru-subtitle">Online Courses And Assignments</div>
      <AssignmentsAsStudent/>
      <div class="columns">
        <div class="column is-6"><InstituteAssociations :associations="associations" /></div>
        <div class="column is-6"><GuruSubscription :instituteOwn="instituteOwn"/></div>
      </div>
    </div>
  </div>
</template>

<script>
import AssignmentsAsStudent from './Assignment/GuruAssignmentsAsStudent'
import InstituteAssociations from '../../components/Guru/GuruInstituteAssociations'
import GuruSubscription from '../../components/Guru/GuruSubscription'
import TeachPage from '../../components/TeachPage/TeachPage.vue'

export default {
  name: 'GuruHome',
  components: { TeachPage, GuruSubscription, InstituteAssociations, AssignmentsAsStudent },
  props: ['associations', 'instituteOwn'],
  inject: ['$validator'],
  data: function () {
    return {

    }
  },
  methods: {}
}
</script>

<style>
  .home-container {
    margin-top: 0.2em;
  }

  .main-section {
    padding: 2%;
  }

  .guru-title {
    font-size: 1.7em;
    color: rgb(88, 88, 90);
    font-weight: bold;
  }

  .guru-subtitle {
    font-size: 1.2em;
    margin-bottom: 0.5em;
    color: rgb(88, 88, 90);
    font-weight: 500;
  }
</style>
