<template>
  <div
    class="table_item"
    :style="info.bg ? 'background : #ebebeb' : 'background : #ffffff'"
  >
    <div>
      <p>{{ info.text1 }}</p>
      <span v-if="info.comingSoon">(coming soon)</span>
    </div>

    <span :class="{ bold: info.bold }">{{ info.text2 }}</span>
  </div>
</template>
<script>
export default {
  name: 'table_item',
  props: {
    info: {
      type: Object
    }
  }
}
</script>
<style lang="scss" scoped>
.table_item {
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    padding: 10px 0px 10px 20px;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #dcdcdc;
    gap: 3px;
    width: 86%;
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;

      color: #000000;
    }
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #000000;
    }
  }
  > span {
    padding: 10px 0px 10px 20px;
    border-width: 0px 1px 1px 0px;
    border-style: solid;
    border-color: #dcdcdc;
    width: 33%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;

    color: #000000;
  }
  .bold {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
  }
}
@media only screen and (max-width: 769px) {
  .table_item {
    div {
      padding: 10px 0px 10px 15px;
      p {
        font-size: 14px;
      }
      span {
        font-size: 12px;
      }
    }
    > span {
      padding: 10px 0px 10px 15px;
      font-size: 12px;
    }
    .bold {
      font-size: 14px;
    }
  }
}
@media only screen and (max-width: 375px) {
  .table_item {
    div {
      padding: 10px 0px 10px 10px;
      p {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
      }
    }
    > span {
      padding: 10px 0px;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      text-align: center;
    }
    .bold {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
    }
  }
}
</style>
