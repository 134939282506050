<template>
  <div class="teach_page">
    <div class="jdoodle_block">
      <TitleText :titleText="teachPageTitle" class="teach_title"/>
      <div class="jdoodle_block_info">
        <div class="jdoodle_left_block">
          <p>Educational Institute and Teachers</p>
          <div>
            <TeachBlockItem
              v-for="item in teachItems"
              :key="item.id"
              :text="item.text"
            />
          </div>
          <div @click="showGuruRegister">
            <MyButton>Create assignments now</MyButton>
          </div>
        </div>
        <div class="jdoodle_right_block">
          <img src="../../assets/images/teachPage/teach_img.png"/>
        </div>
      </div>
    </div>
    <div class="recruiters_block">
      <div class="recruiters_left_block">
        <img src="../../assets/images/teachPage/recruiter.png"/>
      </div>
      <div class="recruiters_right_block">
        <p>Technical Recruiters</p>
        <div class="recruiters_right_block_items">
          <RecruiterBlockItem
            v-for="item in recruiterItems"
            :key="item.id"
            :text="item.text"
          />
        </div>
        <div @click="showGuruRegister">
          <MyButton> Create assignments now</MyButton>
        </div>
      </div>
    </div>
    <div class="table_block">
      <div class="table" v-if="this.$store.state.isUserLoggedIn === false">
        <div class="table_title">
          <p>Create Assignments now</p>
        </div>
        <div class="table_info">
          <TableItem v-for="item in tableInfo" :key="item.id" :info="item"/>
        </div>
      </div>
      <div class="table_button" @click="showGuruRegister">
        <MyButton>Create assignments now</MyButton>
      </div>
    </div>
    <OrganisationBlock/>
    <DownloadBlock/>
  </div>
</template>
<script>
import TitleText from '../TitleText.vue'
import TeachBlockItem from './components/TeachBlockItem.vue'
import MyButton from '../MyButton.vue'
import RecruiterBlockItem from './components/RecruiterBlockItem.vue'
import TableItem from './components/TableItem.vue'
import OrganisationBlock from '../Utils/OrganisationBlock.vue'
import DownloadBlock from '../Utils/DownloadBlock.vue'
import { eventBus } from '../../assets/javascript/event-bus'
import { GURU_EVENTS } from '../../assets/javascript/constants'
import GuruHome from '../Guru/GuruHome.vue'
export default {
  name: 'teach_page',
  components: {
    TitleText,
    TeachBlockItem,
    MyButton,
    RecruiterBlockItem,
    TableItem,
    OrganisationBlock,
    DownloadBlock,
    GuruHome
  },
  data () {
    return {
      teachPageTitle: {
        title: 'JDoodle Guru',
        text: 'Online Courses and Assignments',
        width: '100%'
      },
      teachItems: [
        { id: 1, text: 'Your own virtual institute online' },
        { id: 2, text: 'Create courses and assignments' },
        { id: 3, text: 'Auto-correct and grade assignments' },
        { id: 4, text: 'Collaborate with students and peers' }
      ],
      recruiterItems: [
        { id: 1, text: 'Run your pre-screening online' },
        { id: 2, text: "Assess your candidate's programming skills" },
        { id: 3, text: 'Collaborate with fellow recruiters' }
      ],
      tableInfo: [
        {
          id: 1,
          text1: 'Class',
          text2: 'Free',
          bg: true,
          bold: true
        },
        {
          id: 2,
          text1: 'Private Students',
          text2: 'Unlimited'
        },
        {
          id: 3,
          text1: 'Public Students ',
          text2: 'Unlimited',
          bg: true,
          comingSoon: true
        },
        {
          id: 4,
          text1: 'Teachers',
          text2: 'Unlimited'
        },
        {
          id: 5,
          text1: 'Private Assignments',
          text2: 'Unlimited',
          bg: true
        },
        {
          id: 6,
          text1: 'Public Assignments',
          text2: 'Unlimited',
          comingSoon: true
        },
        {
          id: 7,
          text1: 'Private Courses',
          text2: 'Unlimited',
          bg: true,
          comingSoon: true
        },
        {
          id: 8,
          text1: 'Public Courses',
          text2: 'Unlimited',
          comingSoon: true
        },
        {
          id: 9,
          text1: 'Embed to blog/website',
          text2: 'Unlimited',
          bg: true,
          comingSoon: true
        }
      ]
    }
  },
  methods: {
    showGuruRegister () {
      console.log('clicked')
      if (this.$store.state.isUserLoggedIn === false) {
        eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('../Login'))
      } else {
        eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('../Guru/GuruRegister'), { mode: 'add' })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.jdoodle_block {
  padding: 56px 0px 41px 8%;
  background-color: #f8f9f8;

  .jdoodle_block_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    .jdoodle_left_block {
      display: flex;
      width: 39%;
      flex-direction: column;
      gap: 40px;

      > p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 45px;
        width: 70%;
      }
    }

    .jdoodle_right_block {
      width: 55%;

      img {
        width: 100%;
      }
    }
  }
}

.recruiters_block {
  padding: 110px 11% 105px 0px;
  display: flex;
  align-items: center;

  .recruiters_left_block {
    width: 64%;

    img {
      width: 100%;
    }
  }

  .recruiters_right_block {
    display: flex;
    flex-direction: column;
    gap: 55px;

    > p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 141%;
    }

    .recruiters_right_block_items {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }
}

.table_block {
  padding: 112px 0px;
  background: #f8f9f8;

  .table {
    width: 650px;
    margin: auto;

    .table_title {
      padding: 10px 0px 10px 20px;
      background: #363739;

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;

        color: #ffffff;
      }
    }
  }

  .table_button {
    margin-top: 68px;
    text-align: center;
  }
}

@media only screen and (max-width: 1200px) {
  .jdoodle_block {
    padding: 50px 7px 41px 3%;
  }
  .recruiters_block {
    padding: 93px 6% 86px 0px;
    justify-content: space-between;

    .recruiters_left_block {
      width: 54%;
    }
  }
}

@media only screen and (max-width: 1040px) {
  .jdoodle_block {
    .jdoodle_block_info {
      .jdoodle_left_block {
        width: 46%;

        > p {
          font-size: 27px;
          line-height: 37px;
          width: 90%;
        }
      }

      .jdoodle_right_block {
        width: 50%;
      }
    }
  }
  .recruiters_block {
    .recruiters_left_block {
      width: 46%;
    }

    .recruiters_right_block {
      gap: 42px;

      > p {
        font-size: 28px;
      }

      .recruiters_right_block_items {
        gap: 23px;
      }
    }
  }
}

@media only screen and (max-width: 769px) {
  .jdoodle_block {
    .jdoodle_block_info {
      flex-direction: column-reverse;

      .jdoodle_left_block {
        width: 70%;
        margin-top: 51px;
        align-items: center;
      }

      .jdoodle_right_block {
        width: 70%;
      }
    }
  }
  .recruiters_block {
    flex-direction: column;
    padding: 93px 0% 86px 0px;
    gap: 27px;

    .recruiters_left_block {
      width: 70%;
    }

    .recruiters_right_block {
      width: 65%;
      align-items: center;
    }
  }
  .table_block {
    .table {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 375px) {
  .jdoodle_block {
    padding: 56px 0px 41px 0px;

    .teach_title {
      padding-left: 24px;
    }

    .jdoodle_block_info {
      .jdoodle_left_block {
        padding-left: 24px;
        align-items: start;
        margin-top: 35px;
        width: 95%;
        gap: 24px;

        > p {
          font-weight: 500;
          font-size: 20px;
          line-height: 141%;
          width: 63%;
        }
      }

      .jdoodle_right_block {
        margin-top: 35px;
        width: 100%;
      }
    }
  }
  .recruiters_block {
    padding: 35px 0px 27px 0px;

    .recruiters_left_block {
      width: 100%;
    }

    .recruiters_right_block {
      align-items: start;
      width: 95%;
      padding-left: 24px;
      gap: 30px;

      > p {
        font-weight: 500;
        font-size: 20px;
        line-height: 141%;
        margin-top: 0;
      }

      .recruiters_right_block_items {
      }
    }
  }
  .table_block {
    padding: 35px 0px;

    .table {
      .table_title {
        p {
          font-weight: 400;
          font-size: 14px;
        }
      }
    }

    .table_button {
      margin-top: 35px;
    }
  }
}
</style>
