<template>
  <nav class="panel">
    <p class="panel-heading  has-text-weight-bold has-text-grey-darker">
      Your Assignments as Student
    </p>
    <div class="panel-block has-text-centered" v-if="!hasAssignments">
      <span>No Assignments yet.</span>
    </div>
    <div class="panel-block has-text-centered is-paddingless" v-if="hasAssignments">
      <table class="table is-striped is-bordered is-hoverable is-fullwidth">
        <thead>
        <tr>
          <th>Title</th>
          <th>Start</th>
          <th>End</th>
          <th>Assignment Status</th>
          <th>Submission Status</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="assignment in assignments">
          <td><a class="btn btn-link" @click="viewAssignment(assignment)">{{assignment.title}}</a></td>
          <td>{{getFormattedDate(assignment.startTime)}}</td>
          <td>{{getFormattedDate(assignment.endTime)}}</td>
          <td>{{assignment.assignmentStatus}}</td>
          <td>{{assignment.submissionStatus}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </nav>
</template>

<script>
import formMixin from '../../../assets/javascript/form-mixin'
import moment from 'moment'

export default {
  name: 'assignmentsAsStudent',
  components: { },
  mixins: [formMixin],
  data: function () {
    return {
      assignments: []
    }
  },
  mounted () {
    window._.delay(() => { this.getAssignments() }, 1000)
  },
  methods: {
    getAssignments () {
      if (!this.$store.state.isUserLoggedIn) {
        return
      }

      this.doodleForm.errorMessage = ''
      this.executeAPI({
        url: '/api/guru-api/assignment/assignmentsOfStudent',
        method: 'post',
        data: {
          instituteCode: this.$route.params.instituteCode
        },
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          this.assignments = data.assignments
        },
        jdaEvent: 'get-assignments',
        jdaLabel: ''
      })
    },
    getFormattedDate: function (dte) {
      if (!dte) {
        return ''
      }

      return moment(dte).format('MMMM Do YYYY, h:mm:ss a')
    },
    viewAssignment (assignment) {
      this.$router.push('/guru/ins/' + assignment.insCode + '/assignment/' + assignment.insAssignmentId + '/attend')
    }
  },
  computed: {
    hasAssignments () {
      return this.assignments && this.assignments.length > 0
    }
  }
}
</script>

<style>
  .panel-heading {
    font-family: serif;
    font-size: 16px;
    letter-spacing: 1px;
    color: #333;
  }

  .panel-block {
    display: block;
  }
</style>
